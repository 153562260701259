import React from "react"
import Layout from "../components/Layout/Layout"
import SEO from "../components/seo"
import Collapsible from "react-collapsible"
import { graphql, useStaticQuery } from "gatsby"
import { BLOCKS } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import styled from "styled-components"
import "./accordian.css"

const Paragraph = ({ children }) => <p>{children}</p>
const Header3 = ({ children }) => <h3>{children}</h3>
const Header4 = ({ children }) => <h4>{children}</h4>
const Header5 = ({ children }) => <h5>{children}</h5>
const Header6 = ({ children }) => <h6>{children}</h6>

const options = {
  renderNode: {
    [BLOCKS.HEADING_3]: (node, children) => <Header3>{children}</Header3>,
    [BLOCKS.HEADING_4]: (node, children) => <Header4>{children}</Header4>,
    [BLOCKS.HEADING_5]: (node, children) => <Header5>{children}</Header5>,
    [BLOCKS.HEADING_6]: (node, children) => <Header6>{children}</Header6>,
    [BLOCKS.PARAGRAPH]: (node, children) => <Paragraph>{children}</Paragraph>,
  },
}

const CV = styled.a`
  font-size: calc(0.47vw + 1rem);
  font-family: Leiko;
  color: #faf3c8;
`

const IndexPage = () => {
  const { contentfulStartPage } = useStaticQuery(
    graphql`
      query {
        contentfulStartPage {
          aboutMe {
            json
          }
          cv {
            file {
              url
            }
          }
        }
      }
    `
  )
  return (
    <Layout>
      <SEO title="Home" />
      <Collapsible trigger="About" easing="ease-in-out">
        {documentToReactComponents(contentfulStartPage.aboutMe.json, options)}
      </Collapsible>
      <CV
        href={contentfulStartPage.cv.file.url}
        target="_blank"
        rel="noreferrer"
      >
        CV
      </CV>
    </Layout>
  )
}

export default IndexPage
